<template>
 <div class="panel">
    <div class="main">
      <h3>Settings</h3>
      </div>
    <div class="side-panel">
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavigatorSettings',
};
</script>

<style scoped>
.panel {
  margin: 0;
  background-color: #fff;
  min-height: 20em;
  display: flex;
}

.main {
  padding: 1rem;
  width: calc(100% - 20rem);
}

.side-panel {
  background-color: var(--grey-200);
  margin: 0;
  padding: 1rem;
  width: 20rem;
}
</style>